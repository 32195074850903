<template>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%" v-loading="loading">
        <vue-draggable-next v-model="data" :animation="300" handle=".handle" @change="sort" v-loading="loading">
            <transition-group>
                <template v-for="d in data">
                    <template v-for="image in image_list">
                        <div v-if="image.id === d.image_id" class="handle">
                            <el-image lazy
                                      :src="`${$root.env.CDN_URL}/${image.thumbnail}`"></el-image>
                            <el-icon style="position: absolute;top: 0;right: 0;cursor: pointer" @click="removeImage(d)">
                                <CircleClose/>
                            </el-icon>
                        </div>
                    </template>
                </template>
            </transition-group>
        </vue-draggable-next>
        <el-input prefix-icon="Search" v-model="query"></el-input>
        <el-table style="margin-top: 10px" v-loading="loading" :data="dataPage">
            <el-table-column label="预览图" prop="prefix" width="120">
                <template #default="scope">
                    <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.thumbnail}`"
                              style="width: 100px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="假ID" prop="nickname" width="100"></el-table-column>
            <el-table-column label="类型" prop="type" width="60">
                <template #default="scope">
                    {{ image_type_enum[scope.row.type] }}
                </template>
            </el-table-column>
            <el-table-column label="色块" prop="cells" width="60"></el-table-column>
            <el-table-column label="难度" prop="difficulty" width="60">
                <template #default="scope">
                    <div v-if="scope.row.cells" :style="{color: scope.row.difficulty?'var(--el-color-error)':null}">
                        L{{ scope.row.cells > 800 ? 4 : scope.row.cells > 500 ? 3 : scope.row.cells > 300 ? 2 : 1 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="分类" prop="categories" width="60">
                <template #default="scope">
                    <template v-for="c in scope.row.categories">
                        <template v-for="cc in category_list">
                            <el-tag size="small" v-if="cc.id===c">{{ cc.i18n_name.EN }}</el-tag>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="作者" prop="author" width="100"></el-table-column>
            <el-table-column label="操作" width="100">
                <template #default="scope">
                    <el-button type="primary" @click="addImage(scope.row)" :disabled="loading">添加</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin-top: 10px;text-align: center;">
          <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                         :total="dataFilter.length"></el-pagination>
        </div>
        <template #footer>
            <el-button type="primary" :disabled="loading" @click="reset">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "CollectionImage",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], image_list: [], editing: null, dialog_opened: false, category_list: [], query: '',
            page: 1, size: 50,
            image_status_enum: {0: '测试中', 1: '已通过',},
            image_module_enum: {0: '图库', 1: 'DAILY', 2: '发现页'},
            image_type_enum: {1: '普通', 2: 'GIF', 3: '彩绘', 4: '3d'},
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/category').then(res => {
                    this.category_list = res.data.data.categoryList;
                }),
                axios.get('/cms/v1/image').then(res => {
                    this.image_list = res.data.data.imageList;
                })
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog(collection) {
            this.dialog_opened = this.loading = true;
            this.editing = collection;
            axios.get(`/cms/v1/collection/${collection.id}/image`).then(res => {
                this.data = res.data.data.imageList;
                this.loading = false;
            });
        },
        reset() {
            this.dialog_opened = this.loading = false;
            this.editing.count = this.data.length;
            this.editing = null;
            this.query = '';
        },
        addImage(image) {
            this.loading = true;
            axios.post(`/cms/v1/collection/${this.editing.id}/image`, {image_id: image.id}).then(res => {
                this.data.push(res.data.data);
                this.loading = false;
                this.sort();
            });
        },
        removeImage(r) {
            this.loading = true;
            axios.delete(`/cms/v1/collection/${this.editing.id}/image`, {data: {r_id: r.id}}).then(res => {
                this.data.splice(this.data.indexOf(r), 1);
                this.loading = false;
            });
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id)
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/collection/image`, {sort}).then(res => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.image_list.filter(d => {
                let valid = d.status === 1 && (!this.query || (d.nickname + '').includes(this.query) || d.id.includes(this.query));
                if (valid) {
                    this.data.forEach(dd => {
                        if (dd.image_id === d.id) {
                            valid = false;
                        }
                    })
                }
                return valid
            });
        },
        dataPage() {
          return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size);
        }
    },
}
</script>

<style scoped>
.handle {
    width: 140px;
    margin: 5px;
    position: relative;
    display: inline-block;
}
</style>