<template>
    <div>
        <el-button type="primary" :loading="loading" @click="openDialog()">创建新Bonus</el-button>
    </div>
    <el-table style="margin-top: 10px" :data="data" v-loading="loading">
        <el-table-column width="150" prop="id" label="ID"></el-table-column>
        <el-table-column width="300" prop="content" label="奖励内容"></el-table-column>
        <el-table-column width="100" prop="limit" label="领取次数"></el-table-column>
        <el-table-column width="100" prop="left" label="剩余次数"></el-table-column>
        <el-table-column width="100" prop="url" label="生成链接">
            <template #default="scope">
                <el-button size="small" text type="primary" @click="copy(scope.row)">复制</el-button>
            </template>
        </el-table-column>
        <el-table-column width="200" prop="c_time" label="创建时间">
            <template #default="scope">
                {{ timestampToDatetime(scope.row.c_time) }}
            </template>
        </el-table-column>
        <el-table-column width="150" label="操作">
            <template #default="scope">
                <el-button size="small" type="danger" :loading="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="resetForm">
        <el-form :model="form" label-width="100px" ref="form">
            <el-form-item label="类型">
                <el-radio-group v-model="mode">
                    <el-radio label="image">图片</el-radio>
                    <el-radio label="goods">道具</el-radio>
                    <el-radio label="item">家园物品</el-radio>
                    <el-radio label="vip">会员</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="图片id" prop="image_id" v-if="mode === 'image'">
                <el-select v-model="form.image_id" filterable :disabled="loading">
                    <el-option v-for="m in image_list" :value="m.id" :label="m.nickname"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="钻石" prop="diamond" v-if="mode === 'goods'">
                <el-input-number v-model="form.diamond" :min="0" :max="300" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="魔法棒" prop="magic" v-if="mode === 'goods'">
                <el-input-number v-model="form.magic" :min="0" :max="10" :disabled="loading"></el-input-number>
            </el-form-item>
<!--            <el-form-item label="油漆桶" prop="bucket" v-if="mode === 'goods'">-->
<!--                <el-input-number v-model="form.bucket" :min="0" :max="10" :disabled="loading"></el-input-number>-->
<!--            </el-form-item>-->
            <el-form-item label="炸弹" prop="bomb" v-if="mode === 'goods'">
                <el-input-number v-model="form.bomb" :min="0" :max="10" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label-width="120px" label="会员时间 (小时)" prop="vip" v-if="mode === 'vip'">
              <el-input-number v-model="form.vip" :disabled="loading"></el-input-number>
            </el-form-item>
<!--            <el-form-item label="场景id" prop="scene_id" v-if="mode === 'item'">-->
<!--              <el-select v-model="form.image_id" filterable :disabled="loading">-->
<!--                <el-option v-for="m in image_list" :value="m.id" :label="m.nickname"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item label="物品id" prop="item_id" v-if="mode === 'item'">
              <el-select v-model="form.item_id" filterable :disabled="loading">
                <el-option v-for="m in item_list" :value="m.id" :label="m.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="mode !== 'vip'" label="领取次数" prop="limit">
              <el-input-number v-model="form.limit" :min="1" :max="10000" :disabled="loading"></el-input-number>
              <el-button @click="maxLimit" style="margin-left: 5px" :disabled="loading">Max</el-button>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button text type="primary" :loading="loading" @click="resetForm">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {timestampToDatetime, generateUuid} from "../../libs/utils";
import axios from "ts-axios-new";

export default {
    name: "Bonus",
    data() {
        return {
            loading: false, data: [], dialog_opened: false, image_list: [], mode: 'image',
            item_list: [],
            image_status_enum: {0: '处理中', 1: '测试中', 2: '未通过', 3: '已通过', 4: '处理失败'},
            form: this.creatForm(),
        }
    },
    methods: {
        timestampToDatetime(timestamp) {
            return timestampToDatetime(timestamp)
        },
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/bonus`).then(res => {
                    this.data = res.data.data.bonusList;
                }),
                axios.get(`/cms/v1/image`).then(res => {
                    this.image_list = res.data.data.imageList.filter(d => d.module === 6);
                }),
                axios.get(`/cms/v1/scape/item?bonus=true`).then(res => {
                    this.item_list = res.data.data.items;
                })
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog() {
            this.dialog_opened = true;
        },
        resetForm() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.form = this.creatForm()
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid && (this.form.image_id || this.form.diamond || this.form.bucket || this.form.magic || this.form.bomb|| this.form.vip || this.form.item_id)) {
                    this.loading = true;
                    let content;
                    if (this.mode === 'image') {
                        content = `imageId=${this.form.image_id}`
                    } else if (this.mode === 'goods') {
                        content = `id=${generateUuid()}`;
                        if (this.form.diamond) {
                            content += `,diamond=${this.form.diamond}`;
                        }
                        if (this.form.magic) {
                            content += `,magic=${this.form.magic}`;
                        }
                        if (this.form.bucket) {
                            content += `,bucket=${this.form.bucket}`;
                        }
                        if (this.form.bomb) {
                            content += `,bomb=${this.form.bomb}`;
                        }
                    } else if (this.mode === 'vip') {
                        content = `vip=${this.form.vip}`
                        this.form.limit = 1
                    } else if (this.mode === 'item') {
                        content = `itemId=${this.form.item_id}`
                    }
                    const form = {
                      content,
                      limit: this.form.limit
                    }
                    axios.post(`/cms/v1/bonus`, form).then(res => {
                      this.data.unshift(res.data.data);
                      this.data.id = res.data.data
                      this.resetForm();
                    })
                }
            })
        },
        remove(row) {
            this.loading = true;
            axios.delete(`/cms/v1/bonus/${row.id}`).then(_ => {
                this.data.splice(this.data.indexOf(row), 1);
                this.loading = false;
            })
        },
        copy(row) {
            let url = row.url
            if (!url) {
              const deeplink = `app://rabigame-pixel/bonus?bonusId=${row.id}`
              url = `https://pixel.rabigame.com/landing/index.html?deeplink=${encodeURIComponent(deeplink)}`
            }
            clipboard.writeText(url);
            this.$notify.success('复制成功！');
        },
        creatForm() {
          return {
              image_id: null,
              diamond: 0,
              magic: 0,
              bucket: 0,
              bomb: 0,
              limit: 1,
              vip: 0,
              item_id: null,
          }
        },
        maxLimit() {
          this.form.limit = 10000
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>