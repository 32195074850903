<template>
  <div>
    <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
  </div>
  <el-table style="margin-top: 10px;" v-loading="loading" :data="data">
    <el-table-column label="图片" width="150" prop="image">
      <template #default="scope">
        <el-image :src="`${$root.env.CDN_URL}/${scope.row.cover}`" style="width: 100px;"
                  lazy></el-image>
      </template>
    </el-table-column>
    <el-table-column label="活跃" width="100" prop="active">
      <template #default="scope">
        <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"
                   :inactive-value="false" :active-value="true"></el-switch>
      </template>
    </el-table-column>
    <el-table-column label="类型" width="200" prop="type">
      <template #default="scope">
        {{ popup_type_enum[scope.row.type] }}
      </template>
    </el-table-column>
    <el-table-column label="平台" width="100" prop="platform"/>
    <el-table-column label="频率" width="200" prop="frequency">
      <template #default="scope">
        {{ popup_frequency_enum[scope.row.frequency] }}
      </template>
    </el-table-column>
    <el-table-column label="新手" width="150">
      <template #default="scope">
        <el-tag v-if="scope.row.rookie">新手弹窗</el-tag>
        <!--            <el-tag v-if="scope.row.data && scope.row.data.newbie">新手优惠活动</el-tag>-->
      </template>
    </el-table-column>
    <el-table-column label="操作" width="250">
      <template #default="scope">
        <el-button type="success" circle icon="Top" :loading="loading"
                   :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
        <el-button type="success" circle icon="Bottom" :loading="loading"
                   :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
        <el-button size="small" :dislabed="loading" @click="openDialog(scope.row)">编辑</el-button>
        <el-button type="danger" size="small" :disabled="loading" @click="remove(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog v-model="dialog_opened" :before-close="reset">
    <el-form :model="form" ref="form" label-width="100px">
      <el-form-item label="弹出频率" prop="frequency" :rules="[{required: true, message: '请选择动作'}]">
        <el-select v-model="form.frequency" :disabled="loading">
          <el-option v-for="(v, k) in popup_frequency_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开始天数" prop="day" :rules="[{required: true, message: '请输入从生命周期第几天开始'}]">
        <el-input-number v-model="form.day" :disabled="loading" @change="changeDay"></el-input-number>
      </el-form-item>
      <el-form-item label="平台" prop="platform">
        <el-select v-model="form.platform" :disabled="loading">
          <el-option v-for="p in ['android', 'ios']" :label="p" :value="p"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="动作" prop="type" :rules="[{required: true, message: '请选择动作'}]">
        <el-select v-model="form.type" :disabled="loading" @change="changeType">
          <el-option v-for="(v, k) in popup_type_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="Collection" prop="data.collection_id"-->
<!--                    :rules="[{required: true, message: '请选择collection'}]" v-if="[0].includes(form.type)">-->
<!--        <el-select v-model="collectionValue" :disabled="loading" v-if="form.data" filterable>-->
<!--          <el-option v-for="c in collection_list" :label="c.i18n_name.EN" :value="c.id"-->
<!--                     v-if="form.type===0"></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <!--            <el-form-item label="新手" prop="rookie" v-if="[0].includes(form.type)">-->
      <!--              <el-checkbox v-model="this.form.rookie" :disabled="loading">新手弹窗</el-checkbox>-->
      <!--            </el-form-item>-->
      <el-form-item label="订阅活动" prop="data.id" :rules="[{required: true, message: '请选择订阅活动'}]"
                    v-if="[0,1].includes(form.type)">
        <el-select v-model="subscriptionValue" :disabled="loading" filterable>
          <el-option v-for="c in subscription_list" :value="c.id" :label="c.event_name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="新手" v-if="[0,1].includes(form.type) && form.rookie">
        <el-tag>新手优惠活动</el-tag>
      </el-form-item>
      <el-form-item label="URL" prop="data.url" :rules="[{required: true, message: '请输入url'}]"
                    v-if="form.type === 2">
        <el-input v-model="form.data.url" :disabled="loading"></el-input>
      </el-form-item>

      <el-form-item label="家园岛屿" prop="data.id" :rules="[{required: true, message: '请选择家园岛屿'}]"
                    v-if="[3].includes(form.type)">
        <el-select v-model="form.data.id" :disabled="loading" filterable>
          <el-option v-for="c in scene_list" :value="c.id" :label="c.i18n_name.EN"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="图片" prop="cover" :rules="[{required: true, message: '请上传图片'}]">
        <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                   accept="image/*" :data="{prefix: 'popup'}" :onProgress="handleProgress"
                   :headers="{authorization: $root.authorization}">
          <img v-if="form.cover" :src="`${$root.env.CDN_URL}/${form.cover}`" style="width: 200px">
          <el-button size="small" v-else :loading="loading">上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update, deepcopy} from "../libs/utils";

export default {
  name: "Popup",
  data() {
    return {
      loading: false, data: [], editing: null, dialog_opened: false,
      collection_list: [], series_list: [], subscription_list: [], scene_list: [],
      popup_type_enum: {
        // 0: '进入Collection',
        0: '跳转内购页',
        1: '跳转订阅页',
        2: '跳转URL',
        3: '跳转家园岛屿',
      },
      popup_frequency_enum: {
        0: '仅一次',
        1: '每日一次',
        2: '每次',
      },
      form: {
        cover: '',
        data: null,
        type: null,
        active: false,
        frequency: 0,
        day: 0,
        platform: null,
        rookie: false
      },
    }
  },
  computed: {
    subscriptionValue: {
      get() {
        return this.form.data.id
      },
      set(value) {
        this.form.data.id = value
        this.form.platform = null
        this.form.rookie = false
        this.subscription_list.forEach((subscription) => {
          if (this.form.data.id === subscription.id) {
            this.form.data.start = subscription.start
            this.form.data.end = subscription.end
            this.form.data.sku = subscription.sku
            this.form.platform = subscription.platform
            this.form.rookie = subscription.newbie
          }
        })
      },
    },
    // collectionValue: {
    //   get() {
    //     return this.form.data.collection_id
    //   },
    //   set(value) {
    //     this.form.data.collection_id = value
    //     this.form.day = 0
    //     this.form.rookie = false
    //     if (this.form.type === 0) {
    //       this.collection_list.forEach((collection) => {
    //         if (this.form.data.collection_id === collection.id) {
    //           if (collection.day)
    //             this.form.day = collection.day
    //           if(this.form.day > 0) {
    //             this.form.rookie = true
    //           }
    //         }
    //       })
    //     }
    //   },
    // },
  },
  methods: {
    init() {
      this.loading = true;
      axios.all([
        axios.get('/cms/v1/popup').then(res => {
          this.data = res.data.data.popupList;
        }),
        // axios.get('/cms/v1/collection').then(res => {
        //   this.collection_list = res.data.data.collectionList;
        // }),
        axios.get('/cms/v1/subscription-all').then(res => {
          this.subscription_list = res.data.data.subscriptionList;
        }),

        axios.get(`/cms/v1/scene`).then(res => {
          this.scene_list = res.data.data.sceneList;
        })

      ]).then(_ => {
        this.loading = false;
      })
    },
    openDialog(item) {
      this.dialog_opened = true;
      this.$nextTick(_ => {
        if (item) {
          update(this.form, item);
          this.form.data = deepcopy(item.data);
          this.editing = item;
        }
      });
    },
    reset() {
      this.$refs.form.resetFields();
      this.editing = null;
      this.dialog_opened = this.loading = false;
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.editing) {
            axios.put(`/cms/v1/popup/${this.editing.id}`, this.form).then(res => {
              update(this.editing, res.data.data);
              this.editing.data = res.data.data.data
              this.reset();
            })
          } else {
            axios.post('/cms/v1/popup', this.form).then(res => {
              this.data.unshift(res.data.data);
              this.reset();
            })
          }
        }
      })
    },
    update(row) {
      this.loading = true;
      axios.put(`/cms/v1/popup/${row.id}`, row).then(res => {
        this.loading = false;
      });
    },
    remove(row) {
      this.loading = true;
      axios.delete(`/cms/v1/popup/${row.id}`).then(_ => {
        this.data.splice(this.data.indexOf(row), 1);
        this.loading = false;
      });
    },
    changeDay() {
      // if (this.form.type === 0) {
      //   this.form.rookie = this.form.day && this.form.day > 0
      // }
    },
    changeType() {
      this.form.platform = null
      this.form.rookie = false
      this.form.day = 0
      this.form.data = {};
      // if ([0].includes(this.form.type)) {
      //   this.form.data = {collection_id: ''}
      // }
      if ([0,1].includes(this.form.type)) {
        this.form.data = {id: '', start: "", end: "", sku: ''}
      } else if (this.form.type === 2) {
        this.form.data = {url: ''}
      } else if (this.form.type === 3) {
        this.form.data = {id: ''}
      }
    },
    handleSuccess(res) {
      this.loading = false;
      this.form.cover = res.data.name
    },
    handleProgress() {
      this.loading = true;
    },
    moveUp(index) {
      const row = this.data.splice(index, 1);
      this.data.splice(index - 1, 0, row[0]);
      this.resort();
    },
    moveDown(index) {
      const row = this.data.splice(index, 1);
      this.data.splice(index + 1, 0, row[0]);
      this.resort();
    },
    resort() {
      const sort = [];
      this.data.forEach(d => {
        sort.push(d.id);
      })
      this.loading = true;
      axios.post(`/cms/v1/sort/popup`, {sort}).then(_ => {
        this.loading = false;
      })
    },
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped>

.el-select {
  width: 150px;
}
</style>